import React, {useState, useEffect} from 'react';
import Image from "../../../assets/img/Images";
import {Link, NavLink} from "react-router-dom";
import MobileLangSelect from "../../Elements/MobileLangSelect/MobileLangSelect";
import CustomSelect from "../../Elements/CustomSelect/CustomSelect";
import {useTranslation} from "react-i18next";

function Header(props) {
    const {t} = useTranslation();
    const [scroll, setScroll] = useState(false)
    const [showHeader, setShowHeader] = useState(false)
    const [mobile, setMobile] = useState(false)
    const scrollFunc = () => {
        window.onscroll = function () {
            if (window.pageYOffset >= 10) {
                setScroll(true)
            } else {
                setScroll(false)
            }
        };
    }
    const dedectwidth = () => {
        if (window.innerWidth <= 768) {
            setMobile(true)
        } else {
            setMobile(false)
            setShowHeader(false)
        }
    }
    useEffect(() => {
        scrollFunc()
        dedectwidth()
    })
    return (
        <>
            <div id='navbar' className={`header  ${props.isWhite ? 'white' : ''} ${scroll ? 'active' : ''}  `}>
                {( showHeader) && <div onClick={() => {
                    setShowHeader(false)
                }}></div>}
                <nav>
                    <div className="logo">
                        <Link to='/'>
                            <img src={Image.Logo} alt=""/>
                        </Link>
                    </div>
                    <div className={`nav-menu d-flex ${showHeader && 'show'}`}>
                        <NavLink onClick={() => {
                            setShowHeader(false)
                        }} exact className='nav-link' activeClassName="active"
                                 to="/about"><span>{t('header.about')}</span></NavLink>
                        <NavLink onClick={() => {
                            setShowHeader(false)
                        }} exact className='nav-link' activeClassName="active"
                                 to="/indicators"><span>{t('header.indicators')}</span></NavLink>
                        <NavLink onClick={() => {
                            setShowHeader(false)
                        }} exact className='nav-link' activeClassName="active"
                                 to="/activities"><span>{t('header.activities')}</span></NavLink>
                        <NavLink onClick={() => {
                            setShowHeader(false)
                        }} exact className='nav-link' activeClassName="active"
                                 to="/contact"><span>{t('header.contact')}</span>
                        </NavLink>
                        {mobile &&
                        <div className="mobileElements">
                            <div className="lists">
                                <img src={Image.Ellipse} alt=""/>
                                <img src={Image.Ellipse} alt=""/>
                            </div>
                            <div className="lang">
                                <MobileLangSelect/>
                            </div>
                            <div onClick={() => {
                                setShowHeader(false)
                            }} className="closeIcon">
                                <img src={Image.close} alt=""/>
                            </div>
                        </div>
                        }
                    </div>
                    <div className='flex flex-end flex-align-center'>
                        {!mobile &&
                        <div className="lang pr-1">
                            <CustomSelect isWhite={props.isWhite}/>
                        </div>
                        }
                        {mobile &&
                        <div onClick={() => {
                            setShowHeader(true)
                        }} className="toggler">
                            <button className="btn-menu">
                                <img src={Image.menu} alt=""/>
                            </button>
                        </div>
                        }
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Header;
