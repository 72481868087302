import {
    HOVER,
    OUT,
    TOGGLE,
    SET_LANG,
} from "./../types";
import uluAgro from "../../const/api"

export const changeLanguage = (payload) => {
    return {
        type: SET_LANG,
        payload,
    };
};

export const toggleMenu = () => {
    return {
        type: TOGGLE
    }
}

export const setHover = (hover) => {
    return {
        type: hover ? HOVER : OUT,
    };
};

