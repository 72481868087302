import React , {useState , useEffect} from 'react';
import {changeLanguage} from "../../../redux/actions";
import {withTranslation} from "react-i18next";
import { connect } from "react-redux";

function CustomSelect(props) {
    const [lang, setLang] = useState("az");
    const [select , setSelect] = useState(false);

    useEffect(() => {
            setLang(
                localStorage.getItem("locale") ? localStorage.getItem("locale") : "az"
            );
        } , [lang]
    )

    const changeLang = (lang) => {
        const { i18n } = props;
        i18n.changeLanguage(lang);
        localStorage.setItem("locale", lang);
        props.changeLanguage(lang);
        setLang(lang);
    };
    return (
        <div className={`customselect ${props.isWhite ? 'white': ''}`}>
            <div className="select" onClick={()=>{setSelect(!select)}}>
                <div className="selected">
                    <p>
                        <span>{lang}</span>
                    </p>
                </div>
                {select &&
                <div className="choose animated fadeIn">
                    {lang !== 'az' &&
                    <p  onClick={() => changeLang("az")}>
                        <span>AZ</span>
                    </p>
                    }
                    {lang !== 'en' &&
                    <p  onClick={() => changeLang("en")}>
                        <span>EN</span>
                    </p>
                    }
                </div>
                }
            </div>
        </div>
    );
}

export default connect(null, { changeLanguage })(withTranslation()(CustomSelect));
