import React from 'react';
import Image from "../../../assets/img/Images";
import {useTranslation} from "react-i18next";

function Home(props) {
    const {t} = useTranslation();

    return (
        <div>
            <div className="main position-relative">
                <div className="row align-items-center my-md-5 my-2 py-md-5 py-2">
                    <div className="col-lg-7 main-text animated fadeInLeftBig">
                        <p>{t('home.slogan')}</p>
                        <span>{t('home.description')}</span>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-end animated fadeInRightBig">
                        <div className="img-box">
                            <img src={Image.Img7} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}



export default Home;

