import React from 'react';
import Image from "../../../assets/img/Images"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Top(props) {
    const {t} = useTranslation();
    return (
        <div className="position-relative ">
            <div className="d-md-flex justify-content-between align-items-center top animated fadeInDownBig">
                <div className="d-md-flex d-block">
                    <div className="d-flex align-items-center">
                        <img src={Image.Gps} alt=""/>
                        <span>{t('top.location')}</span>
                    </div>
                    <div className="d-flex mx-md-5 mx-0 align-items-center">
                        <img src={Image.Phone} alt=""/>
                        <span> +(994 50) 511 55 15</span>
                    </div>
                </div>
                <div className="d-flex">
                    <Link className="mx-2">
                        <img src={Image.Facebook} alt=""/>
                    </Link>
                    <Link className="mx-2">
                        <img src={Image.Youtube} alt=""/>
                    </Link>
                    <Link className="mx-2">
                        <img src={Image.Whatsapp} alt=""/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Top;
