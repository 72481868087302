export const az = {
    top: {
        location: ' Azərbaycan, Bakı. Time Business Center. 4 Mərtəbə.'
    },
    header: {
        about: 'Haqqımızda',
        indicators: 'Göstəricilər',
        activities: 'Fəaliyyətlərimiz',
        contact: 'Əlaqə',
    },
    home: {
        slogan: 'Azərbaycan kənd təsərrüfatı',
        description: 'Dövlət dəstəyi əsasında “Ulu Aqro” MMC, Qax AqroPark 2017-ci ildə Qax rayonu ərazisində 2076 ha sahədə yaradılmışdır. Aqro Park ölkənin ərzaq təhlükəsizliyinin təmin edilməsi, idxaldan asılılığın azaldılması, regionda məşğulluğun artırılması, ənənəvi təcrübəyə əsaslanan müasir metodlardan və ən son texnologiyalardan daha səmərəli istifadə edilməsi, Azərbaycan məhsullarının ölkəyə gətirilməsi və layiqincə təmsil olunması məqsədilə yaradılıb.'
    },
    about: {
        header: 'ŞİRKƏTİMİZ HAQQINDA',
        description: "Ulu Agro MMC, QaxAqro Parkı 2017-ci ildə dövlət dəstəyi əsasında Qax Rayonu ərazisində 2076 ha ərazidə təsis edilmişdir. Aqro Park ölkədə ərzaq təhlükəsizliyinin təmin edilməsi, idxaldan asılılığın azaldılması, regionda məşğulluğun artırılması, əkin sahələrinin ənənəvi təcrübələrə arxalanaraq müasir üsullar və son texnologiya ilə daha səmərəli istifadə edilməsi və Azərbaycanda istehsal olunan məhsulların xarici bazarlara çıxarılması və layiqincə təmsil edilməsi məqsədilə yaradılmışdır. Yarandığı gündən dinamik inkişaf xətti izləyən Parkda yüksək keyfiyyəti və məhsuldarlığı olan geniş çeşiddə meyvə, tərəvəz və dənli bitkilər yetişdirilir. Əldə olunan məhsullara əlavə dəyər qatmaq və regionda olan kiçik və orta biznes subyektlərinə dəstək şirkətin təməl prinsipləri sırasındadır.Beynəlxalq standartlara uyğun keyfiyyətli və sağlam məhsullarımız xarici bazarda möhkəmlənməyimizə imkan yaradır və hədəflərimizdən biri “Made in Azerbaijan” brendini bütün dünyaya tanıtmaqdır. Eyni zamanda şirkətimiz sosial məsuliyyət proqramlarında iştirak edir.",
        list1: 'Parkda yetişdirilən məhsullar dənli bitkilər və meyvə bağları olaraq iki qrupa bölünür. Bitkiçilik buğda, qarğıdalı, arpa və soya kimi məhsullardan ibarətdir, meyvə bağları isə armud, alma, ərik, gavalı, gilas, şaftalı, qoz, üzüm və badamdan ibarətdir.',
        list2: '700 hektar əkin sahəsində 28 ədəd ABŞ istehsalı olan Vally pivot sistemi quraşdırılmışdır; 650 hektar əkin sahələri də yağış suyu ilə suvarılır. 750 hektardan çox torpağı əhatə edən meyvə bağları Türkiyə, İspaniya və İsraildən gələn tam avtomatik damcı suvarma sistemləri ilə təchiz edilmişdir',
        list3: 'Aqroparkda 4500 ton tutumlu müasir standartlarla silos tipli anbarlar və 8000 ton taxıl anbarı tikilmişdir.',
        list4: 'Su ehtiyacları üçün 50 000, 20 000 və 10 000 kub metrlik 3 su hovuzu inşa edilmişdir.',
        list5: '99 ədəd müxtəlif növ zəruri texnika alınmışdır.',
    },
    indicators: {
        main_indicators: 'ƏSAS GÖSTƏRİCİLƏR',
        main_indicators_description: 'Bunlar 2021-ci il üçün bitkiçilik və bağçılıq sahəsində ən son göstəricilərimizdir.',
        product_name: 'Məhsulun Adı',
        sort: 'Növü',
        area: 'Əkin sahəsi (ha)',
        average_productivity: 'Orta Məhsuldarlıq (kq)',
        obtained: 'Əldə Olunan Məhsul',
        wheat: 'Buğda',
        corn: 'Qarğıdalı',
        barley: 'Arpa',
        soya: 'Soya',
        brew: 'Dəmyə',
        pivot: 'Pivot',
        first_reap: 'Birinci biçin',
        repeat: 'Təkrar',
        total: 'TOPLAM',
        result: '2021-ci ildə istehsal olunan 3000 tona yaxın məhsulun 92 %-i xarici bazarlara ixrac olunmuşdur. “Made in Azerbaijan” brendinin dünyada tanıdılması və ölkəyə valyuta gətirilməsi uğurlarımızdan biridir.',
        fruit_garden: 'Meyvə bağları (ha)',
        kind_of: 'Çeşidlər',
        almonds: 'Badam',
        grapes: 'Üzüm',
        nut: 'Qoz',
        pear: 'Armud',
        apple: 'Alma',
        plum: 'Gavalı',
        apricot: 'Qaysı',
        cherry: 'Gilas',
        peach_nectarine : 'Şaftalı, Nektarin',
        indicators_info: '2019-cu ildə əkilən üzüm bağlarında yüksək məhsuldarlıq əldə edilmişdir. Belə ki, hər hektar üzrə ortalama 14 ton keyfiyyətli üzüm yığılmış və həcmin 98%-i Rusiya və Ukrayna bazarlarına ixrac edilmişdir. ',
        season_plants: 'BİTKİLƏRİN MÖVSÜMLÜLÜYÜ',
        season_plants_description: 'Davamlı ərzaq zəncirinin əhəmiyyətini yüksək qiymətləndirərək və müştərilərimiz adından müasir dünyanın çağırışları qarşısında onu qoruyaraq bitkilərin mövsümlülüyünün önəmli rol oynadığını xüsusilə qeyd edirik. Burada bitki hasilatı cədvəlini müştərilərimizə təqdim edirik.',
    },
    activities: {
        control: 'İDARƏETMƏ MƏRKƏZİ',
        control_text: 'Peşəkar komandamızın gücü GÜCÜMÜZDÜR!\n' +
            'Dünyanın qabaqcıl şirkətləri ilə sıx əməkdaşlığımız, onların təcrübələrinin öyrənilməsi, tətbiqi və eyni zamanda, Aqro Parkda çalışan xarici mütəxəssislərimizin yerli mütəxəssislərlə vəhdəti inkişafımızı daha da dinamik edir.',
        tourism_garden: 'EKO TURIZM BAĞI',
        tourism_garden_text: 'Aqro Park ərazisində 50 hektarı əhatə edən Eko Turizm bölməsi yaradılmışdır. Sahənin yaradılması regionda kənd təsərrüfatı turizmi mədəniyyətinin formalaşdırılması və inkişafı məqsədi daşıyır.\n' +
            'Ziyarət edən qonaqlar bütün Aqro Park ərazisində yetişdirilən meyvələr və çeşidlərini, eyni zamanda regiona xas olan yerli sortlarla Eko Turizm bölməsində tanış ola bilərlər.',
        logistic_park: 'LOGİSTİKA PARKI',
        logistic_park_text: 'ULUAGRO MMC aqroparkla əlaqəli yeni tikilmiş logistika mərkəzini də idarə edir. Kənd təsərrüfatı məhsullarının artımında və istehsalın davamlı genişlənməsində logistikanın əhəmiyyəti həmişə yüksək qiymətləndirilir, iqtisadiyyat və nəqliyyat həlləri olmadan əkinçilik sahəsindəki hər hansı bir layihə inkişafda böyük çətinliklərlə üzləşəcək. Beləliklə, logistika mərkəzinin inkişafı bizim üçün çox vacib bir hadisədir. Bu mərkəz məhsulu ən qısa müddətdə toplamaq, saxlamaq, qablaşdırmaq və nəql etmək üçün lazım olan bütün infrastrukturu və imkanlarını ehtiva edir.\n' +
            'Logistika mərkəzinin ümumi sahəsi 5480 kv.m olan 4 anbardan, aqro texnika üçün qaraj və texnika anqarlarından, yük maşınları üçün çəki nəzarət sistemindən, dayanacaq qurğularından, yanacaqdoldurma məntəqəsindən, ofisləri olan mərkəzləşdirilmiş idarəetmə mərkəzindən, oteldən, təhlükəsizlik sistemindən və s. ibarətdir.',
        material: 'MADDİ TEXNİKİ BAZA',
        material_text: 'Kənd təsərrüfatı sahəsində qabaqcıl texnologiyaların öyrənilməsi və istifadəsi şirkətimiz üçün adət-ənənə halını almışdır. Əkin sahələrinin effektiv və səmərəli istifadəsi üçün bazamızda 99 ədəd texnika və avadanlıq mövcuddur. Torpağın işlənməsindən başlayaraq budama və meyvə yığımına qədər bir çox vacib işlər tam avtomatlaşdırılmışdır.',
        innovation: 'KƏND TƏSƏRRÜFATI SAHƏSİNDƏ İNNOVASİYA',
        innovation_text : 'Çətin görünməsinə baxmayaraq, kənd təsərrüfatı sahəsi hər il böyük yeniliklərin meydana gəldiyi məkandır.\n' +
            'Və biz də bu prosesin bir hissəsiyik. Timesoft MMC-dəki tərəfdaşlarımızla birlikdə xüsusi olaraq aqroparklar və məhsul yetişdirən müəssisələr üçün nəzərdə tutulmuş unikal və müasir ağıllı idarəetmə sistemini inkişaf etdirdik. Sistemin əsas funksiyaları aşağıdakılardır:',
        innovation_list1: 'İşçilərin məsuliyyətindən asılı olaraq tam fərdiləşdirilmiş profillər və giriş nöqtələri;',
        innovation_list2: 'Peyk vasitəsi ilə və ərazilərdə quraşdırılan Davis cihazlarından alınan datalar əsasında qabaqlayıcı tədbirlər görmək;',
        innovation_list3: 'Əməliyyatlar, idarəetmə qərarları, xəritəçəkmə, strukturlar, etiketləmə və qaynaq xərclərinin hamısını bir yerdə qurmaq imkanı;',
        innovation_list4: 'Vaxt cədvəlləri və müalicələr daxil olmaqla bitkilər və torpaq sahələri üçün tam məlumat mövcudluğu;',
        innovation_list5: 'Təşkilat daxilində bütün idarəetmə və əməliyyat fəaliyyətlərini idarə etmək və təşkil etmək üçün xüsusi planlaşdırma;',
        innovation_list6: 'Avadanlıqların, qurğuların, maşınların istifadəsinin və təhlükəsizlik sistemlərinin nəzərdən keçirilməsinə nəzarət.',
        diversification: 'ŞAXƏLƏNMƏ',
        diversification_text: 'Böyümə həyatın həqiqətidir, güclənmək və daim dəyişən iş mühitinə uyğunlaşmaq üçün zərurətdir. Sürətli böyüməni dəstəkləmək üçün əsas fəaliyyətlərimizlə əlaqədar bir neçə layihə də hazırladıq.',
        diversification_list1: 'Timesoft MMC: İT həlli yollarının inkişafı və inteqrasiyası üçün təsis edilmişdir, vəzifələrə ERP və ya CRM sistemlərinin, digər verilənlər bazası funksiya sistemlərinin təkmilləşdirilmiş formalarının yaradılması daxildir. Timesoft MMC -nin bacarıqlı komandası müştərinin şərtlərinə və planlarına əsaslanan hər növ layihəni idarə etmə imkanına malikdir.',
        diversification_list2: 'Madena MMC: bütün ixrac fəaliyyətlərimizi həyata keçirən və xarici müştərilərə göndərişləri təşkil edən ticarət şirkətidir. İxrac xəritəmizdə artıq Rusiya və Ukrayna var və onu hər il genişləndirməyi planlaşdırırıq.',
        diversification_list3: ' Multimoverz MMC: Nəqliyyat və logistika şirkəti, çatdırılma və ixracat ehtiyaclarımızı təmin etmək üçün yaradılmışdır. Hava yükü, dəmir yolu, yük maşını və konteyner daşımaları da daxil olmaqla hər cür logistika xidmətləri təklif edir, hətta layihə göndərmələri şirkətin bacarıqlı komandasının səlahiyyəti daxilindədir.\n' +
            'Ticarət şirkətimiz ULU CARDENS markası ilə istehsal etdiyimiz məhsulları Rusiya və Ukrayna bazarlarında satışını təşkil edir. Müştərilərimiz üçün dayanıqlı və etibarlı təchizatçı və partnyor olmağımız digər təsərrüfatlarında məhsullarının eyni marka ilə ixracını həyata keçirməyimizə imkan yaradır. İxrac coğrafiyasının genişləndirilməsi üçün daim danışıqlar aparılır. Təcrübəli komandamız dünyanın ən böyük sərgilərində iştirak edir, öz təcrübələrini və əlaqələrini artırmağa nail olurlar. Bunlara misal olaraq, Fruit Logistica Berlin, Sial Paris, World Food Moscow, World Food Dubai, Anuga China sərgilərini qeyd etmək olar.',
        future: 'GƏLƏCƏK PLANLAR',
        future_text: 'Müəssisəmizin bölgədə güclü bir kənd təsərrüfatı mərkəzi yaratmaq üçün yol xəritəsi var və bu vəzifəni yerinə yetirmək üçün biz bir neçə gələcək planı həyata keçirmək niyyətindəyik.\n' +
            'Belə ki, hazırda biz aqroparkın ərazisində müxtəlif məhsul və meyvələr üçün ümumi tutumu 6000 ton olan mərhələlisoyuducu anbar layihəsini işləyirik. Bununla yanaşı ikinci və üçüncü nəsil hazır məhsullar üçün badam emalı xətti, meyvə qurutma avadanlıqlarının gətirilməsi istiqamətində araşdırmalar aparılır.\n' +
            'Meyvə bağlarının artırılması da gələcək planlarımızın bir parçasıdır. Əldə etdiyimiz yüksək məhsuldarlıq və keyfiyyətə əsaslanaraq xüsusilə də üzüm və badam bağlarının genişləndirilməsi prioritet olaraq görülür. ',

    },
    contact: {
        contact: 'ƏLAQƏ',
        address: 'Address',
        address_info: 'Qax, Azərbaycan',
        phone: 'Telefon',
        email: 'Email',
        write_to_us: 'Bizə yaz',
        name: "Ad",
        surname: 'Soyad',
        person_email: 'Email',
        message: 'Mesaj',
        send: 'Göndər'
    }
}
