import React from 'react';
import Image from "../../../assets/img/Images";
import Table from 'react-bootstrap/Table'
import {useTranslation} from "react-i18next";

function Indicators(props) {
    const {t} = useTranslation()
    return (
        <div>
            <div className="main position-relative about-component">
                <div className="row my-5 py-5">
                    <div className="col-lg-8 about animated fadeInLeftBig">
                        <div>
                            <p>{t('indicators.main_indicators')}</p>
                            <span>{t('indicators.main_indicators_description')}</span>
                        </div>
                        <div className="product-table">
                            <Table>
                                <thead>
                                <tr>
                                    <th>{t('indicators.product_name')}</th>
                                    <th>{t('indicators.sort')}</th>
                                    <th>{t('indicators.area')}</th>
                                    <th>{t('indicators.average_productivity')}</th>
                                    <th>{t('indicators.obtained')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{t('indicators.wheat')}</td>
                                    <td>{t('indicators.brew')}</td>
                                    <td>648</td>
                                    <td>2495</td>
                                    <td>1617010</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.wheat')}</td>
                                    <td>{t('indicators.pivot')}</td>
                                    <td>401</td>
                                    <td>6510</td>
                                    <td>2610510</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.corn')}</td>
                                    <td>{t('indicators.first_reap')}</td>
                                    <td>200</td>
                                    <td>10499</td>
                                    <td>2099820</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.corn')}</td>
                                    <td>{t('indicators.repeat')}</td>
                                    <td>100</td>
                                    <td>8500</td>
                                    <td>850000*</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.barley')}</td>
                                    <td>{t('indicators.brew')}</td>
                                    <td>50</td>
                                    <td>4262</td>
                                    <td>213080</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.barley')}</td>
                                    <td>{t('indicators.pivot')}</td>
                                    <td>50</td>
                                    <td>6510</td>
                                    <td>315000</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.soya')}</td>
                                    <td>{t('indicators.pivot')}</td>
                                    <td>7.5</td>
                                    <td>4500</td>
                                    <td>33750*</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('indicators.total')}</td>
                                    <td>1456,5</td>
                                    <td></td>
                                    <td>7739179</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-start animated fadeInRightBig">
                        <div className="about-img-right">
                            <img src={Image.Img19} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-5">
                    <div className="col-lg-7 d-flex justify-content-start animated fadeInRightBig">
                        <div className="about-img-right">
                            <img src={Image.Img17} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 about animated fadeInLeftBig">
                        <div>
                            <span>{t('indicators.result')}</span>
                        </div>
                        <div className="product-table">
                            <Table>
                                <thead>
                                <tr>
                                    <th>{t('indicators.fruit_garden')}</th>
                                    <th></th>
                                    <th>{t('indicators.kind_of')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{t('indicators.almonds')}</td>
                                    <td>356</td>
                                    <td>FARRAGNES, FERRADUAL, AVİJHOR, GUARA </td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.grapes')}</td>
                                    <td>138</td>
                                    <td>SULTANİ, SUPERİOİR, RED GLOBE, CRİMSON, MİCHELE PARİER</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.nut')}</td>
                                    <td>89,4</td>
                                    <td>CHANDLER, FRANQUETTE</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.pear')}</td>
                                    <td>34,6</td>
                                    <td>DEVECİ, SANTAMARİA,NASHI, MARIA MARGARIT</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.apple')}</td>
                                    <td>39,49</td>
                                    <td>JEROMİN, SCARLET SUPUR, REDCHİEF, GRANNY SMİTH,GOLDEN,ROSY GLOW</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.plum')}</td>
                                    <td>23,58</td>
                                    <td>BLACK DIAMOND? BLACK AMBER? SANTA ROSA? BLACK SPLENDOR</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.apricot')}</td>
                                    <td>15,9</td>
                                    <td>SEKERPARE, PERFECT RED</td>
                                </tr><tr>
                                    <td>{t('indicators.cherry')}</td>
                                    <td>30,4</td>
                                    <td>REGİNA, KORDİA, ZO9OO,STARKS GOLD</td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.peach_nectarine')}</td>
                                    <td>31,3</td>
                                    <td>CALDESİ 200, VENUS, ELEGANTLAYD, CRISTHAVEN </td>
                                </tr>
                                <tr>
                                    <td>{t('indicators.total')}</td>
                                    <td>759 ha</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-5 align-items-center">
                    <div className="col-lg-7 about animated fadeInLeftBig">
                        <div className="product-table">
                            <Table>
                                <thead>
                                <tr>
                                    <th>{t('indicators.fruit_garden')}</th>
                                    <th></th>
                                    <th>{t('indicators.kind_of')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{t('indicators.almonds')}</td>
                                    <td>356</td>
                                    <td>FARRAGNES, FERRADUAL, AVİJHOR, GUARA </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-start animated fadeInRightBig">
                        <div className="about-img-right">
                            <img src={Image.Img13} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-5 align-items-center">
                    <div className="col-lg-7 d-sm-flex d-block justify-content-between animated fadeInRightBig">
                        <div className="about-img-left mr-sm-2 my-sm-0 my-2">
                            <img src={Image.Img14} alt=""/>
                        </div>
                        <div className="about-img-right ml-sm-2 my-sm-0 my-2">
                            <img src={Image.Img15} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 about animated fadeInLeftBig">
                        <div>
                            <span>{t('indicators.indicators_info')}</span>
                        </div>
                        <div className="product-table">
                            <Table>
                                <thead>
                                <tr>
                                    <th>{t('indicators.fruit_garden')}</th>
                                    <th></th>
                                    <th>{t('indicators.kind_of')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{t('indicators.grapes')}</td>
                                    <td>138</td>
                                    <td>SULTANİ, SUPERİOİR, RED GLOBE, CRİMSON, MİCHELE PARİER</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="my-5 py-5">
                    <div className="about animated fadeInLeftBig">
                        <p>{t('indicators.season_plants')}</p>
                        <span>{t('indicators.season_plants_description')}</span>
                    </div>
                    <div className="my-3">
                        <img className="w-100" src={Image.Indicator} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Indicators;
