import React from 'react';
import Image from "../../../assets/img/Images";
// import {Link} from "react-router-dom";
// import Image from "../../../assets/img/Images";
import {useTranslation} from "react-i18next";

function Contact(props) {
    const {t} = useTranslation()
    return (
        <div>
            <div className="main position-relative contact">
                <div className="row my-5 py-5">
                    <div className="col-lg-7 animated fadeInLeftBig">
                        <h4>{t('contact.contact')}</h4>
                        <div className="d-sm-flex d-block justify-content-between align-items-center mb-5">
                            <div>
                                <p>{t('contact.address')}</p>
                                <span>{t('contact.address_info')}</span>
                            </div>
                            <div>
                                <p>{t('contact.phone')}</p>
                                <span>+994 50 0000000</span>
                            </div>
                            <div>
                                <p>{t('contact.email')}</p>
                                <span>info@uluagro.az</span>
                            </div>
                        </div>
                        <p>{t('contact.write_to_us')}</p>
                        <form action="" className="my-3">
                            <div className="d-sm-flex d-block justify-content-between ">
                                <div className="inputs my-sm-0 my-3">
                                    <label htmlFor="">{t('contact.name')}</label>
                                    <input type="text"/>
                                </div>
                                <div className="inputs my-sm-0 my-3">
                                    <label htmlFor="">{t('contact.surname')}</label>
                                    <input type="text"/>
                                </div>
                                <div className="inputs my-sm-0 my-3">
                                    <label htmlFor="">{t('contact.email')}</label>
                                    <input type="email"/>
                                </div>
                            </div>
                            <div className="inputsMessage my-3">
                                <label htmlFor="">{t('contact.message')}</label>
                                <textarea></textarea>
                            </div>
                        </form>
                        <div className="d-flex justify-content-end">
                            <button>{t('contact.send')}</button>
                        </div>
                    </div>
                    <div className="col-lg-5 mt-lg-0 mt-3 d-flex justify-content-start animated fadeInRightBig">
                        <div className="map">
                            <img src={Image.Map} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Contact;
