import React from 'react';
import Image from "../../../assets/img/Images";
import {useTranslation} from "react-i18next";
function Activities(props) {
    const {t} = useTranslation()

    return (
        <div>
            <div className="main position-relative about-component">
                <div className="row my-5 py-3 align-items-center">
                    <div className="col-lg-7 about animated fadeInLeftBig">
                        <p>{t('activities.control')}</p>
                        <span>{t('activities.control_text')}</span>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-start animated fadeInRightBig">
                        <div className="about-img-right">
                            <img src={Image.Img18} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-3 align-items-center">
                    <div className="col-lg-5 d-flex animated fadeInLeftBig">
                        <div className="about-img-left">
                            <img src={Image.Img5} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 about animated fadeInRightBig d-flex justify-content-start">
                        <div>
                            <p>{t('activities.tourism_garden')}</p>
                            <span>{t('activities.tourism_garden_text')}</span>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-3 align-items-center">
                    <div className="col-lg-7 about animated fadeInLeftBig">
                        <p>{t('activities.logistic_park')}</p>
                        <span>{t('activities.logistic_park_text')}</span>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-start animated fadeInRightBig">
                        <div className="about-img-right">
                            <img src={Image.Img11} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-3 align-items-center">
                    <div className="col-lg-5 d-flex animated fadeInLeftBig">
                        <div className="about-img-left">
                            <img src={Image.Img7} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 about animated fadeInRightBig d-flex justify-content-start">
                        <div>
                            <p>{t('activities.material')}</p>
                            <span>{t('activities.material_text')}</span>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-3 align-items-center">
                    <div className="col-lg-7 about animated fadeInLeftBig">
                        <p>{t('activities.innovation')}</p>
                        <span>{t('activities.innovation_text')}</span>
                        <ul>
                            <li>{t('activities.innovation_list1')}</li>
                            <li>{t('activities.innovation_list2')}</li>
                            <li>{t('activities.innovation_list3')}</li>
                            <li>{t('activities.innovation_list4')}</li>
                            <li>{t('activities.innovation_list5')}</li>
                            <li>{t('activities.innovation_list6')}</li>
                        </ul>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-start animated fadeInRightBig">
                        <div className="about-img-right">
                            <img src={Image.Img12} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-3 align-items-center">
                    <div className="col-lg-5 d-flex animated fadeInLeftBig">
                        <div className="about-img-left">
                            <img src={Image.Img20} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 about animated fadeInRightBig d-flex justify-content-start">
                        <div>
                            <div>
                                <p>{t('activities.diversification')}</p>
                                <span>{t('activities.diversification_text')}</span>
                                <ul>
                                    <li>{t('activities.diversification_list1')}</li>
                                    <li>{t('activities.diversification_list2')}</li>
                                    <li>{t('activities.diversification_list3')}</li>
                                </ul>
                            </div>
                            <div className="act-partners d-sm-flex my-5 justify-content-between align-items-center">
                                <div className="my-sm-0 my-4">
                                    <img src={Image.Madena} alt=""/>
                                </div>
                                <div className="my-sm-0 my-4">
                                    <img src={Image.Gardens} alt=""/>
                                </div>
                                <div className="my-sm-0 my-4">
                                    <img src={Image.Timesoft} alt=""/>
                                </div>
                                <div className="my-sm-0 my-4">
                                    <img src={Image.Multi} alt=""/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row my-5 py-3 align-items-center">
                    <div className="col-lg-7 about animated fadeInLeftBig">
                        <p>{t('activities.future')}</p>
                        <span>{t('activities.future_text')}</span>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-start animated fadeInRightBig">
                        <div className="about-img-right">
                            <img src={Image.Img2} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Activities;
