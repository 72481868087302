import React from 'react';
import Image from "../../../assets/img/Images";
import {useTranslation} from "react-i18next";

function About(props) {
    const {t} = useTranslation();
    return (
        <div>
            <div className="main position-relative about-component">
                <div className="row my-5 align-items-center">
                    <div className="col-lg-8 about animated fadeInLeftBig">
                        <p>{t("about.header")}</p>
                        <span>{t('about.description')}</span>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-start animated fadeInRightBig">
                        <div className="about-img-right">
                            <img src={Image.Img1} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row my-5 align-items-center">
                    <div className="col-lg-4 d-flex animated fadeInLeftBig">
                        <div className="about-img-left">
                            <img src={Image.Img10} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-8 about animated fadeInRightBig d-flex justify-content-start">
                        <ul>
                            <li>{t('about.list1')}</li>
                            <li>{t('about.list2')}</li>
                            <li>{t('about.list3')}</li>
                            <li>{t('about.list4')}</li>
                            <li>{t('about.list5')}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default About;
