import {
    HOVER,
    OUT,
    TOGGLE,
    GET_LANG,
    SET_LANG,
} from "./../types";


export const hoverReducer = (hover = false , action) =>{
    switch (action.type){
        case HOVER:
            return true;
        case OUT:
            return false;
        default :
            return hover ;
    }
}

export const toggleReducer = (data = false, action) =>{
    switch (action.type){
        case TOGGLE:
            return !data;
        default :
            return data;
    }
}

export function langReducer(
    lang = localStorage.getItem("locale") || "az",
    action
) {
    switch (action.type) {
        case SET_LANG:
            return action.payload;
        case GET_LANG:
            return lang;
        default:
            return lang;
    }
}