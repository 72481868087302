import React, {useRef} from 'react';
import Image from "../../../assets/img/Images";
import Slider from "react-slick";
import {Link} from "react-router-dom";


function Footer(props) {
    const partnersSlider = useRef();

    const partnersSettings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        slidesToShow: 7,
        slidesToScroll: 1,
        arrows : false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 561,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    };


    return (
        <div>
            <div className="container-fluid">
                <div className="footer position-relative">
                    <Slider
                        ref={slider => (partnersSlider.current = slider)}
                        {...partnersSettings}>
                        <div>
                            <img src={Image.Tenias} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Vkus} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Aybarus} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Viveros} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Holland} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Mars} alt=""/>
                        </div>
                        <div>
                            <img src={Image.X5} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Spar} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Atg} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Valley} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Yarce} alt=""/>
                        </div>
                        <div>
                            <img src={Image.Netafim} alt=""/>
                        </div>
                    </Slider>
                    <div>
                        <p>All right reserved by layf.az ©</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
