export const en = {
    top: {
        location: 'Azərbaijan, Baku. Time Business Center. 4th Floor.'
    },
    header: {
        about: 'About',
        indicators: 'Indicators',
        activities: 'Activities',
        contact: 'Contact',
    },
    home: {
        slogan: 'Azerbaijan agriculture',
        description: 'On the basis of state support, Ulu Agro LLC, Gakh AgroPark was established in 2017 in the territory of Gakh District on the area of 2076 ha. Agro Park was established to ensure food security in the country, reduce dependence on imports, increase employment in the region, more efficient use of modern methods and latest technologies based on traditional practices, and to bring and properly represent Azerbaijani products in '
    },
    about: {
        header: 'ABOUT OUR COMPANY',
        description: "On the basis of state support, Ulu Agro LLC, Gakh AgroPark was established in 2017 in the territory of Gakh District on the area of 2076 ha. Agro Park was established to ensure food security in the country, reduce dependence on imports, increase employment in the region, more efficient use of modern methods and latest technologies based on traditional practices, and to bring and properly represent Azerbaijani products in foreign markets. Following its dynamic development since its establishment, a wide range of fruits, vegetables and cereals with high quality and productivity are grown in the Park. Adding value to the products and supporting small and medium-sized businesses in the region are among the company's core principles. Our quality and healthy products in accordance with international standards allow us to strengthen in foreign markets, and one of our goals is to promote the brand of ‘’Made in Azerbaijan’’ around the world. At the same time, our company participates in social responsibility programs.",
        list1: 'The products grown in the park are divided into two groups: cereals and orchards. Crop production consists of wheat, corn, barley and soybeans, and orchards consist of pears, apples, apricots, cherries, peaches, walnuts, grapes and almonds.',
        list2: '28 Vally pivot system made in the USA were installed on 700 hectares of sown area; 650 hectares of sown land are also irrigated with rainwater. The orchards, which cover more than 750 hectares, are equipped with fully automatic drip irrigation systems from Turkey, Spain and Israel.',
        list3: 'Silo-type warehouses with a capacity of 4,500 tons and 8,000 tons of grain warehouses were built in the agro-park.',
        list4: '3 water basins of 50,000, 20,000 and 10,000 cubic meters were built for water needs.',
        list5: '99 different types of necessary equipment were purchased.',
    },
    indicators: {
        main_indicators: 'MAIN INDICATORS',
        main_indicators_description: 'These are our latest indicators in the field of crop production and gardening for 2021.',
        product_name: 'Product name',
        sort: 'Sort',
        area: 'Sown area (ha)',
        average_productivity: 'Average productivity (kq)',
        obtained: 'The resulting product',
        wheat: 'Wheat',
        corn: 'Corn',
        barley: 'Barley',
        soya: 'Soya',
        brew: 'Brew',
        pivot: 'Pivot',
        first_reap: 'First reap',
        repeat: 'Repeat',
        total: 'TOTAL',
        result: 'In 2021, 92% of about 3,000 tons of products were exported to foreign markets. One of our successes is the promotion of the brand of ‘’Made in Azerbaijan’’ in the world and bringing currency to the country.',
        fruit_garden: 'Orchards (ha)',
        kind_of: 'Assortments',
        almonds: 'Almonds',
        grapes: 'Grapes',
        nut: 'Nut',
        pear: 'Pear',
        apple: 'Apple',
        plum: 'Plum',
        apricot: 'Apricot',
        cherry: 'Cherry',
        peach_nectarine : 'Peach, Nectarine',
        indicators_info: 'High productivity was achieved in the vineyards planted in 2019. Thus, an average of 14 tons of quality grapes were harvested per hectare, and 98% of the harvested product was exported to the Russian and Ukrainian markets.',
        season_plants: 'Seasonality of plants',
        season_plants_description: 'While appreciating the importance of a sustainable food chain and protecting it in the face of the challenges of the modern world on behalf of our customers, we emphasize that plant seasonality plays an important role. Here we present the plant production schedule to our customers.',
    },
    activities: {
        control: 'CONTROL CENTER',
        control_text: 'THE STRENGTH OF OUR PROFESSIONAL TEAM IS OUR STRENGTH! Our close cooperation with the world\'s leading companies, the study and application of their experience, as well as the integration of our foreign experts working with Agro Park with local experts make our development even more dynamic.',
        tourism_garden: 'ECO TOURISM GARDEN',
        tourism_garden_text: 'An Eco-Tourism section covering 50 hectares has been established in the Agro Park area. The purpose of the field is to form and develop the culture of agricultural tourism in the region. Visitors can get acquainted with the fruits and their types grown throughout the Agro Park, as well as local types specific to the region in the Eco Tourism section.',
        logistic_park: 'LOGISTICS PARK',
        logistic_park_text: 'ULUAGRO LLC also manages a newly built logistics center related to the agro-park. The importance of logistics in the growth of agricultural production and the sustainable expansion of production is always appreciated, without economic and transport solutions, any project in the field of agriculture will face great difficulties in development. Thus, the development of the logistics center is a very important event for us. The center has all the infrastructure and facilities needed to collect, store, pack and transport the product as soon as possible. The logistics center consists of 4 warehouses with a total area of 5480 square meters, garages and hangars for agricultural machinery, weight control system for trucks, parking facilities, filling station, centralized control center with offices, hotel, security system and etc.',
        material: 'MATERIAL-TECHNICAL BASE',
        material_text: 'The study and use of advanced technologies in the field of agriculture has become a tradition for our company. We have 99 units of machinery and equipment for the efficient and effective use of sown area. Many important works, from tillage to prunning and fruit harvesting, are fully automated.',
        innovation: 'INNOVATION IN AGRICULTURE',
        innovation_text : 'Despite its difficult appearance, the agricultural sector is a place where great innovations occur every year. And it should be noted we are part of that process. Together with our partners at Timesoft LLC, we have developed a unique and modern intelligent management system specifically designed for agro-parks and growing enterprises. The main functions of the system are:',
        innovation_list1: 'Fully personalized profiles and access points depending on the responsibilities of employees;',
        innovation_list2: 'Take preventive measures based on data from satellite and Davis devices installed in the area;',
        innovation_list3: 'Ability to put together operations, management decisions, mapping, structures, labeling and welding costs;',
        innovation_list4: 'Availability of complete information for plants and land plots, including timelines and treatments;',
        innovation_list5: 'Special planning to manage and organize all management and operational activities within the organization;',
        innovation_list6: 'Monitoring the operation of equipment, facilities, machinery and security systems.',
        diversification: 'DIVERSIFICATION',
        diversification_text: 'Growth is a fact of life, a necessity to grow stronger and adapt to a constantly changing work environment. We have also developed several projects related to our core activities to support rapid growth.',
        diversification_list1: 'Timesoft LLC: Founded for the development and integration of IT solutions, the tasks include the establishment of ERP or CRM systems, improved forms of other database function systems. Timesoft LLC\'s skilled team has the ability to manage any type of project based on the terms and plans of customer.',
        diversification_list2: 'Madena LLC: is a trading company that carries out all our export activities and organizes shipments to foreign customers. We already have Russia and Ukraine on our export map, and we plan to expand it every year.',
        diversification_list3: 'Multimoverz LLC: A transport and logistics company established to meet our\n' +
            '                                        delivery and export needs. It offers all kinds of logistics services, including\n' +
            '                                        air freight, rail, truck and container transportation, and even project\n' +
            '                                        shipments are within the authority of the company\'s skilled team.\n' +
            '                                        Our trading company organizes the sale of our products under the ULU CARDENS\n' +
            '                                        brand in the Russian and Ukrainian markets. Being a stable and reliable supplier\n' +
            '                                        and partner for our customers allows us to export products of the same brand to\n' +
            '                                        other farms. Negotiations are constantly underway to expand the geography of\n' +
            '                                        exports. Our experienced team participates in the largest exhibitions in the\n' +
            '                                        world, manages to increase their experience and contacts. We can mention Fruit\n' +
            '                                        Logistica Berlin, Sial Paris, World Food Moscow, World Food Dubai, Anuga China\n' +
            '                                        exhibitions as examples.',
        future: 'FUTURE PLANS',
        future_text: 'Our company has a road map to establish a strong agricultural center in the region, and we intend to implement several future plans to fulfill this task.\n' +
            'Thus, we are currently working on a phased cold storage project with a total capacity of 6,000 tons for various products and fruits in the agro-park. In addition, research is being conducted in direction to import almond processing line for second and third generation finished products and fruit drying equipment.\n' +
            'Increasing the size of orchards is also part of our future plans. Based on the high productivity and quality we have achieved, the expansion of vineyards and almond orchards is considered a priority.\n',

    },
    contact: {
        contact: 'CONTACT',
        address: 'Address',
        address_info: 'Gakh, Azerbaijan',
        phone: 'Phone',
        email: 'Email',
        write_to_us: 'Write to us',
        name: "Name",
        surname: 'Surname',
        person_email: 'Email',
        message: 'Message',
        send: 'Send'
    }
}
