import Logo from './Logo.svg'
import Gps from './gps.svg'
import Phone from './call.svg'
import Facebook from './facebook.svg'
import Youtube from './youtube.svg'
import Whatsapp from './whatsapp.svg'
import Tenias from './tenias.png'
import Vkus from './vkus.png'
import Aybarus from './aybarus.png'
import Viveros from './viveros.png'
import Holland from './holland.png'
import Mars from './mars.png'
import X5 from './x5.png'
import Spar from './spar.png'
import Atg from './atg.png'
import Valley from './valley.png'
import Yarce from './yarce.png'
import Netafim from './netacim.png'
import Yesterday from './yesterday.png'
import Img1 from './1.png'
import Img2 from './2.png'
import Img3 from './3.png'
import Img4 from './4.png'
import Img5 from './5.png'
import Img6 from './6.png'
import Img7 from './7.png'
import Img8 from './8.png'
import Img9 from './9.png'
import Img10 from './10.png'
import Img11 from './11.png'
import Img12 from './12.png'
import Img13 from './13.png'
import Img14 from './14.png'
import Img15 from './15.png'
import Img16 from './16.png'
import Img17 from './17.png'
import Img18 from './18.png'
import Img19 from './19.jpg'
import Img20 from './20.jpg'
import Ellipse from './ellipse.png'
import Madena from './madena.png'
import Timesoft from './timesoft.png'
import Multi from './multi.png'
import Gardens from './gardens.png'
import Map from './map.png'
import menu from './menu.png'
import close from './error.png'
import Indicator from './indicator.svg'

export default  {
    Logo,
    Gps,
    Phone,
    Facebook,
    Youtube,
    Whatsapp,
    Tenias,
    Vkus,
    Aybarus,
    Viveros,
    Holland,
    Mars,
    X5,
    Spar,
    Atg,
    Valley,
    Yarce,
    Netafim,
    Yesterday,
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
    Img17,
    Img18,
    Img19,
    Img20,
    Ellipse,
    Timesoft,
    Gardens,
    Multi,
    Madena,
    Map,
    menu,
    close,
    Indicator,

}
